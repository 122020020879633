
import { Component, Vue } from 'vue-property-decorator'

import ProgressList from '@/components/shared/ProgressList.vue'
import Welcome from '@/components/welcome/WelcomeComponent.vue'

import { RECORDING_STATE, AGE_OPTION_KIDS_VALUE, TRANSCRIPTS_AGE } from '@/constants'

import { getModule } from 'vuex-module-decorators'
import Configuration from '@/store/modules/configuration'
import Transcripts from '@/store/modules/transcripts'
import User from '@/store/modules/user'
import Recording from '@/store/modules/recording'
import RecordingPart from '@/store/modules/recordingPart'
import Translations from '@/store/modules/translations'

import Store from '@/store/index'

const configuration = getModule(Configuration)
const transcripts = getModule(Transcripts)
const user = getModule(User, Store)
const recording = getModule(Recording, Store) // Accessor for recordings state.
const recordingPart = getModule(RecordingPart, Store)
const translations = getModule(Translations)

// Keys for accessing translations json
const HELLO_KEY = 'recordingsReturnHelloText'
const MAIN_KEY = 'recordingsReturnText'
const CARRY_ON_KEY = 'recordingsReturnCarryOnText'
const RESTART_KEY = 'recordingsReturnRestartText'

@Component({
  components: {
    ProgressList,
    Welcome
  }
})
export default class ReturnFromSessionnView extends Vue {
  helloText = translations.getTranslations(HELLO_KEY)
  mainText = translations.getTranslations(MAIN_KEY)
  carryOnText = translations.getTranslations(CARRY_ON_KEY)
  restartText = translations.getTranslations(RESTART_KEY)

  mounted () {
    // As it will be the first screen loaded, we need to load those
    configuration.load()
  }

  handleCarryOnClicked () {
    recording.updateStatus(RECORDING_STATE.IDLE)

    transcripts.setTranscriptionType(user.getAgeGroup === AGE_OPTION_KIDS_VALUE ? TRANSCRIPTS_AGE.KIDS : TRANSCRIPTS_AGE.DEFAULT)

    this.$router.push('/recordings')
  }

  handleRestartClicked () {
    user.reset()
    recording.reset()
    recordingPart.reset()

    this.$router.push('/')
  }
}

